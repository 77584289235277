import React from "react"
import RelatedArticles from "./RelatedArticles"

const Sidebar = ({ current, related }) => {
  const links = [
    {
      title: "Unlock iPhone 12",
      url:
        "https://www.officialsimunlock.com/en-gb/unlock-phone/iphone/iphone-12-pro-max",
    },
    {
      title: "Unlock iPhone 11",
      url:
        "https://www.officialsimunlock.com/en-gb/unlock-phone/iphone/iphone-11-pro-max",
    },
    {
      title: "Unlock iPhone X",
      url:
        "https://www.officialsimunlock.com/en-gb/unlock-phone/iphone/iphone-x",
    },
    {
      title: "IMEI Check Service",
      url: "https://www.officialsimunlock.com/en-gb/imei-check",
    },
    {
      title: "Network Check by IMEI",
      url: "https://www.officialsimunlock.com/en-gb/imei-check/network-check",
    },
  ]

  return (
    <div className="sidebar">
      {related ? <RelatedArticles current={current} /> : ""}

      <div className="quick-links">
        <h4>Quick Links</h4>
        <nav>
          <ul>
            <li>
              <a href="https://www.officialsimunlock.com">
                Official Sim Unlock
              </a>
            </li>
            {links.map((link, index) => (
              <li key={index}>
                <a href={link.url}>{link.title}</a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  )
}
export default Sidebar
