import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

const RelatedArticles = ({ current }) => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
        edges {
          node {
            id
            excerpt
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              articleslug
              featured_image {
                src {
                  childImageSharp {
                    fluid(maxWidth: 1110, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                alt
              }
            }
            slug
          }
        }
      }
    }
  `)

  const posts = data.allMdx.edges

  return (
    <div className="related-articles">
      <ol>
        {posts
          .filter(({ node }) => node.id !== current)
          .map(({ node }) => {
            const title = node.frontmatter.title || node.slug
            let featuredImgFluid =
              node.frontmatter.featured_image.src.childImageSharp.fluid
            let slug = `/${node.frontmatter.articleslug}`

            if (node.frontmatter.articleslug === "/") {
              slug = "/"
            }
            return (
              <li key={node.slug}>
                <div className="related-articles__item">
                  <Link to={slug}>
                    <Img
                      fluid={featuredImgFluid}
                      alt={node.frontmatter.featured_image.alt}
                      className="related-articles__image"
                    />
                  </Link>
                  <h4>
                    <Link to={slug}>
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h4>
                </div>
              </li>
            )
          })}
      </ol>
    </div>
  )
}
export default RelatedArticles
